import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import BetInput from "./BetInput";
import CartCard from "./CartCard";
import CartBtn from "./CartBtn";
import BetOutput from "./BetOutput";

import { getCartInfo, submitPicks } from '../APIs';

import { TbActivityHeartbeat } from "react-icons/tb";
import { CgChevronDoubleDown } from "react-icons/cg";

import { supabase } from '../auth/client';

import BarLoader from "react-spinners/BarLoader";

function PicksCart(props) {

    const [walletBalance, setWalletBalance] = useState(0);
    const [promoBalance, setPromoBalance] = useState(0);
    const [freeEntryBalance, setFreeEntryBalance] = useState(0);
    const [entryInputValue, setEntryInputValue] = useState("");
    const [freeEntry, setFreeEntry] = useState(false);
    const [multiplier, setMultiplier] = useState(0);
    const [reduced, setReduced] = useState(0.00)
    const [boost, setBoost] = useState(0);
    const [hasCC, setHasCC] = useState(false);
    const [member, setMember] = useState(false);
    const [promoCode, setPromoCode] = useState("");

    const [first, setFirst] = useState(null);

    const boxRef = useRef(null);
    const [isOverflowing, setIsOverflowing] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const isValidBetAmount = (betAmount, pickCount) => {

        // Try to convert betAmount to float
        let bet = parseFloat(betAmount)
    
        // Check if betAmount is a valid number
        if (isNaN(bet)) {
            props.setErrorMsg({type: "amount", msg: "Entry amount must be a valid number"})
            return false
        }

        // Check if betAmount is below minimum (1) and not free
        if (bet < 1 && bet > 0) {
            props.setErrorMsg({type: "amount", msg: "The minimum entry amount is $1, except for free entries"})
            return false
        }

        // Check if betAmount is 0
        if (bet == 0) {
            if (!freeEntry) {
                props.setErrorMsg({type: "amount", msg: "The minimum entry amount is $1, except for free entries"})
                return false
            }
        }
    
        if (pickCount < 4 && bet > 200) {
            props.setErrorMsg({type: "amount", msg: `The maximum entry amount is $200 for ${pickCount}-pick entries`})
            return false
        }

        // Check if betAmount has no more than 2 decimal places
        let decimalPlaces = (betAmount.split('.')[1] || []).length;
        if (decimalPlaces > 2) {
            props.setErrorMsg({type: "amount", msg: "Entry amount must be 2 decimal places or less"})
            return false
        }
    
        // If all checks pass, return true
        return true
    }

    const handleSubmit = async () => {

        const simplePicks = props.selectedPicks.map((pickObj) => (
            {
                "boardID": pickObj._id,
                "bet": pickObj.bet
            }
        ))

        // check betAmount
        if (!isValidBetAmount(entryInputValue, simplePicks.length)) {
            return
        }

        // check number of picks
        if (!props.selectedPicks || props.selectedPicks.length < 2) {
            props.setErrorMsg({type: "picks", msg: "Minimum of 2 picks required"})
            return
        } else if (props.selectedPicks.length > 6) {
            props.setErrorMsg({type: "picks", msg: "Maximum of 6 picks allowed"})
            return
        }

        // TODO: check promoCode

        const userData = {
            picks: simplePicks,
            freeEntry: freeEntry,
            promoCode: promoCode,
            betAmount: freeEntry ? "0" : entryInputValue,
            token: props.session.access_token
        }

        const data = await submitPicks(userData)

        if (data.status === 401) {
            props.setSession(null)
            setWalletBalance(0)
            setPromoBalance(0)
            setFreeEntryBalance(0)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (data.status !== 200) {
            if (data.errorObj) {
                props.setErrorMsg(data.errorObj)
            } 
            return
        }
        navigate('/myentries')
        return
    }

    const fetchBalance = async () => {
        if (first === null) {
            setIsLoading(true)
            setFirst(true)
        }
        const response = await getCartInfo({token: props.session.access_token})
        
        if (response.status === 401) {
            props.setSession(null)
            setWalletBalance(0)
            setPromoBalance(0)
            setFreeEntryBalance(0)
            const { error } = await supabase.auth.signOut()
            navigate('/login')
            return
        } else if (response.status !== 200) {
            return
        }

        setWalletBalance(parseFloat(response.info.walletBalance.$numberDecimal))
        setPromoBalance(parseFloat(response.info.promoBalance.$numberDecimal))
        setFreeEntryBalance(response.info.freeEntryBalance)
        setBoost(response.info.boost)
        setHasCC(response.info.hasCC)
        setMember(response.info.member)
        setIsLoading(false)
        return
    }

    useEffect(() => {
        let newMultiplier = 0
    
        if (props.session) {
            fetchBalance()
            if (boxRef.current.scrollHeight > boxRef.current.clientHeight) {
                setIsOverflowing(true);
            } else {
                setIsOverflowing(false);
            }
        }

        // used for reductions
        let tempReduced = 0.00
        let overs = 0
        let unders = 0
        let matches = new Set()
        // tracks number of overs per match (match = 'team-opp': overs count)
        let oversPerMatch = {}
        // tracks number of unders per match (match = 'team-opp': unders count)
        let undersPerMatch = {}

        if (props.selectedPicks.length < 5) {

            for (const pick of props.selectedPicks) {
                const match = [pick.team, pick.opp].sort().join('-');
                matches.add(match)

                if (pick.bet === 'UNDER') {
                    unders += 1
                    if (!undersPerMatch[match]) {
                        undersPerMatch[match] = 0
                    }
                    undersPerMatch[match]++
                } else if (pick.bet === 'OVER') {
                    overs += 1
                    if (!oversPerMatch[match]) {
                        oversPerMatch[match] = 0
                    }
                    oversPerMatch[match]++
                }
            }

            const numOfMatches = matches.size

            console.log(numOfMatches)

            // 1 MATCH
            if (numOfMatches === 1) {
                // 2/2 picks OVER/OVER or UNDER/UNDER
                // 3/3 picks OVER/OVER/OVER or UNDER/UNDER/UNDER
                if (overs === 0) {
                    // 2/2 picks UNDER/UNDER
                    if (unders === 2) {
                        tempReduced = Math.max(0.1, tempReduced)
                    }
                    // 3/3 picks UNDER/UNDER/UNDER
                    else if (unders === 3) {
                        tempReduced = Math.max(0.12, tempReduced)
                    } 
                } else if (unders === 0) {
                    // 2/2 picks OVER/OVER
                    if (overs === 2) {
                        tempReduced = Math.max(0.1, tempReduced)
                    }
                    // 3/3 picks OVER/OVER/OVER
                    else if (overs === 3) {
                        tempReduced = Math.max(0.12, tempReduced)
                    } 
                }

                // 3 picks from same match
                if (overs + unders >= 3) {
                    tempReduced = Math.max(0.10, tempReduced)
                }
            }
            // 2 MATCHES
            else if (numOfMatches === 2) {
                // TODO: ignores 5/6 picks for now
                if (overs + unders === 3 || overs + unders === 4) {
                    // 2/3 picks OVER/OVER or UNDER/UNDER from same match (regardless of 3rd pick)
                    // 3/3 picks OVER/OVER/OVER or UNDER/UNDER/UNDER
                    if (Object.values(oversPerMatch).some(value => value >= 2)) {
                        tempReduced = Math.max(0.08, tempReduced)
                    }
                    if (Object.values(undersPerMatch).some(value => value >= 2)) {
                        tempReduced = Math.max(0.08, tempReduced)
                    }
                }
                // 4/4 picks OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER
                // 2/4 picks OVER/OVER or UNDER/UNDER from match1 AND 2/4 picks OVER/OVER or UNDER/UNDER from match2 
                if (overs + unders === 4) {
                    // 4/4 picks OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER
                    if (overs === 4 || unders === 4) {
                        tempReduced = Math.max(0.15, tempReduced)
                    }
                    // 2/4 picks OVER/OVER or UNDER/UNDER from match1 AND 2/4 picks OVER/OVER or UNDER/UNDER from match2 
                    if (Object.values(oversPerMatch).some(value => value === 2) && Object.values(undersPerMatch).some(value => value === 2)) {
                        tempReduced = Math.max(0.12, tempReduced)
                    }
                }
            }
            // 3+ MATCHES
            else {
                // TODO: ignores 5/6 picks for now
                // 3/3 picks OVER/OVER/OVER or UNDER/UNDER/UNDER
                if (overs + unders === 3) {
                    if (unders === 3 || overs === 3) {
                        tempReduced = Math.max(0.08, tempReduced)
                    } 
                }
                // 4/4 picks OVER/OVER/OVER/OVER or UNDER/UNDER/UNDER/UNDER
                else if (overs + unders === 4) {
                    if (unders === 4 || overs === 4) {
                        tempReduced = Math.max(0.15, tempReduced)
                    } 
                }
            }
        }

        // multiplier calculation
        if (props.selectedPicks.length < 2) {
            newMultiplier = 0.00
        } else if (props.selectedPicks.length === 2) {
            newMultiplier = (3.00 * (1.00 - tempReduced)) + ((3.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 3) {
            newMultiplier = (5.00 * (1.00 - tempReduced)) + ((5.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 4) {
            newMultiplier = (10.00 * (1.00 - tempReduced)) + ((10.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 5) {
            newMultiplier = (17.00 * (1.00 - tempReduced)) + ((17.00 * (1.00 - tempReduced)) * boost)
        } else if (props.selectedPicks.length === 6) {
            newMultiplier = (28.00 * (1.00 - tempReduced)) + ((28.00 * (1.00 - tempReduced)) * boost)
        }
    
        setReduced(tempReduced)
        setMultiplier(Math.round(newMultiplier * 100) / 100);
    
    }, [props.selectedPicks, props.session]);

    // fixes padding to account for scrollbar in cart
    useLayoutEffect(() => {
        if (boxRef.current.scrollHeight > boxRef.current.clientHeight) {
            setIsOverflowing(true);
        } else {
            setIsOverflowing(false);
        }
    }, [props.selectedPicks]);

    return (
        <Box sx={{ 
            height: '75vh', 
            border: '1px solid rgba(255, 255, 255, 0.2)', 
            position: 'sticky', 
            top: '90px', 
            boxsizing: 'border-box', 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            minWidth: '365px', 
            width: '100%', 
            borderRadius: '16px', 
        }}>
            {isLoading ?
                <Box ref={boxRef} sx={{ overflowY: 'auto', height: '100%', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }} >
                    <BarLoader
                        color="#A80000"
                        loading="true"
                        height={6}
                        width={220}
                        data-testid="loader"
                    />
                </Box>
            :
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: '20px 24px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'flex-start', gap: '25px' }}>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'white' }} >
                                Current Entry
                            </Typography>
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'rgba(255, 255, 255, 0.2)' }} >
                                {props.selectedPicks.length} Player{props.selectedPicks.length > 1 || props.selectedPicks.length === 0 ? 's' : ''} Selected
                            </Typography>
                        </Box>
                        <a onClick={() => props.setSelectedPicks([])} style={{ textDecorationColor: 'white', cursor: 'pointer' }} >
                            <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: '#FFF600' }} >
                                Clear
                            </Typography>
                        </a>
                    </Box>
                    <Box ref={boxRef} sx={{ overflowY: 'auto', height: '100%', boxSizing: 'border-box' }} >
                        <Box sx={{ position: 'absolute', left: -24, top: 150 }}>
                            <CartBtn setCartOpen={props.setCartOpen} cartOpen={props.cartOpen} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '10px', pl: '20px', pr: isOverflowing ? '2px' : '20px', boxSizing: 'border-box' }}>
                            {props.selectedPicks.map((pickObj, index) => (
                                <CartCard key={index} pickObj={pickObj} selectedPicks={props.selectedPicks} setSelectedPicks={props.setSelectedPicks} />
                            ))}
                        </Box>
                        {props.selectedPicks.length < 2 ? 
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: props.selectedPicks.length === 0 ? '100%' : '60%', alignItems: 'center' }}>
                                <img src='/people.svg' height={'80px'} ></img>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '12px', color: 'white', mt: '16px' }} >
                                    You can complete your entry
                                </Typography>
                                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                    when 2+ players are selected.
                                </Typography>
                            </Box>
                        :
                            null
                        }
                    </Box>
                    <Box sx={{ p: '4px 24px 20px 24px' }}>
                        {props.selectedPicks.length >= 2 ?
                            <Box sx={{ display: 'flex', alignContent: 'flex-start', mb: '3px', alignItems: 'center' }}>
                                <TbActivityHeartbeat color="#A80000" size={24} />
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'white', ml:'4px' }} >
                                    {multiplier > 0 && multiplier.toFixed(2)}
                                </Typography>
                                {reduced > 0 ?
                                        <Tooltip 
                                            title={
                                                <Typography sx={{ fontFamily: 'Poppins', fontSize: '11px', my: '-2px', color: 'whitesmoke' }}>
                                                    {reduced * 100}% reduction for this pick combo
                                                </Typography>
                                            } 
                                            placement="right" arrow 
                                            slotProps={{
                                                popper: {
                                                    modifiers: [
                                                        {
                                                            name: 'offset',
                                                            options: {
                                                                offset: [-2, -10],
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CgChevronDoubleDown color="gray" size={20} style={{ marginBottom: '2px' }}/> 
                                            </Box>
                                        </Tooltip>
                                    :
                                        null
                                }
                                { freeEntryBalance > 0 && props.selectedPicks.length === 6 ? 
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '2px', ml: '10px' }}>
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'rgba(255, 255, 255, 0.5)', mr: '6px' }} >
                                            or
                                        </Typography>
                                        <Checkbox 
                                            onChange={() => {
                                                setFreeEntry(!freeEntry) 
                                                setEntryInputValue("0") 
                                            }}
                                            checked={freeEntry}
                                            sx={{ 
                                                p: 0, 
                                                color: '#A80000',
                                                '&.Mui-checked': {
                                                    color: '#A80000',
                                                },
                                            }} 
                                        />
                                        <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '13px', color: 'white' }} >
                                            Use 1 Free Entry
                                        </Typography>
                                    </Box>
                                :
                                    null
                                }
                            </Box>
                        :
                            null
                        }
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                            <BetInput entryInputValue={entryInputValue} setEntryInputValue={setEntryInputValue} freeEntry={freeEntry} />
                            <BetOutput entryInputValue={freeEntry ? 20 : entryInputValue * multiplier} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: {xs:'fit-content', sm:'115px'}, ml: {xs:'6px', sm:'8px'} }}>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                    Balance: ${walletBalance > 0 ? walletBalance.toFixed(2) : 0.00.toFixed(2)}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'white' }} >
                                    Promo: ${promoBalance > 0 ? promoBalance.toFixed(2) : 0.00.toFixed(2)}
                                </Typography>
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '10px', color: 'rgba(255, 255, 255, 0.5)' }} >
                                    Free Entries: {freeEntryBalance > 0 ? freeEntryBalance : 0}
                                </Typography>
                            </Box>
                        </Box>
                        {
                            props.validLocation === undefined && props.session ?
                                <Button variant="contained" onClick={() => {props.session ? props.setErrorMsg({type: "location", msg: "To play AgentPicks, allow 'Location' in browser settings"}) : navigate('/login')}} sx={{ 
                                    bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                    color: 'white',
                                    width: '95%',
                                    height: '52px',
                                    '&:hover': {
                                        backgroundColor: '#A80000',
                                    },
                                    fontFamily: 'Montserrat',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    mt: '16px'
                                }}>
                                    LOCATION REQUIRED
                                </Button>
                            :
                                !props.validLocation && props.validLocation !== undefined && props.session ?
                                    <Button variant="contained" onClick={() => {props.session ? props.setErrorMsg({type: "location", msg: `To play AgentPicks, you must live in a valid state. ${props.detectedState} is not a valid state`}) : navigate('/login')}} sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                        color: 'white',
                                        width: '95%',
                                        height: '52px',
                                        '&:hover': {
                                            backgroundColor: '#A80000',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        fontFamily: 'Montserrat',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        mt: '16px'
                                    }}>
                                        INVALID LOCATION
                                    </Button>
                                :
                                    <Button variant="contained" onClick={() => {props.session ? handleSubmit() : navigate('/login')}} sx={{ 
                                        bgcolor: 'rgba(168, 0, 0, 0.8)', 
                                        color: 'white',
                                        width: '95%',
                                        height: '52px',
                                        '&:hover': {
                                            backgroundColor: '#A80000',
                                            boxShadow: '1px 1px 1px black !important',
                                        },
                                        fontFamily: 'Montserrat',
                                        fontWeight: 600,
                                        fontSize: '16px',
                                        mt: '16px'
                                    }}>
                                        {props.session ? 'Place Entry' : 'Login'}
                                    </Button>
                        }
                        {!isLoading && !member && props.session?
                            <Box sx={{ mt: '16px', display: 'flex', justifyContent: 'center' }} onClick={() => navigate('/settings')} >
                                <Typography sx={{ fontFamily: 'Poppins', fontWeight: 500, fontSize: '12px', color: 'white', cursor: 'pointer', textDecorationLine: 'underline' }} >
                                    Become a prestige member to boost winnings by 5%
                                </Typography>
                            </Box>
                        :
                            null
                        }
                    </Box>
                </>
            }
        </Box>
    );
}
    
export default PicksCart;
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';

import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";

import { supabase } from '../auth/client';
import { getSettings, saveSettings } from "../APIs";
import PersonaComponent from "../components/PersonaComponent";
import SettingsSkeleton from "../skeletons/SettingsSkeleton";


function SettingsPage(props) {

  const [email, setEmail] = useState("")
  const [address, setAddress] = useState("")
  const [birthday, setBirthday] = useState("")
  const [isVerified, setIsVerified] = useState(false)
  const [member, setMember] = useState(false)
  const [phone, setPhone] = useState("")
  const [creatorCode, setCreatorCode] = useState("")
  const [referralCode, setReferralCode] = useState("")
  const [wasReferred, setWasReferred] = useState(false)
  const [saved, setSaved] = useState(null)

  const [updated, setUpdated] = useState(false)

  const [errorMsg, setErrorMsg] = useState({});

  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()

  const location = useLocation();
  const from = location.state && location.state.from

  const handleCreatorCodeChange = (event) => {
    setCreatorCode(event.target.value)
  }

  const handleReferralCodeChange = (event) => {
    setReferralCode(event.target.value)
  }

  // gets a user's settings (from users DB)
  const fetchSettings = async () => {
    setIsLoading(true)
    const response = await getSettings({token: props.session.access_token})
    
    if (response.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (response.status !== 200) {
      return
    }

    setEmail(response.data.email)
    setAddress(response.data.address)
    setBirthday(response.data.birthday)
    setIsVerified(response.data.isVerified)
    // updates isVerified in app
    props.setIsVerified(response.data.isVerified)
    setMember(response.data.member)
    setPhone(response.data.phone)
    setCreatorCode(response.data.creatorCode)
    setReferralCode(response.data.referralCode)
    setWasReferred(response.data.referralCode !== "" ? true : false)
  
    setIsLoading(false)

    return
  }

  function isValidCreatorCode(creatorCode) {
    // Regular expression that matches strings of length 2-9 containing only alphanumeric characters
    const regex = /^[a-zA-Z0-9]{2,9}$/;
    return regex.test(creatorCode);
  }

  function isValidReferralCode(referralCode) {
    // Regular expression that matches strings of length 24 containing only alphanumeric characters
    const regex = /^[a-zA-Z0-9]{24}$/;
    return regex.test(referralCode);
  }

  const handleSubmit = async () => {

    setErrorMsg({})

    const userData = {
      phone: phone ? phone : "",
      creatorCode: creatorCode ? creatorCode : "",
      referralCode: referralCode ? referralCode : "",
      token: props.session.access_token
    }

    // check if phone number is valid
    if (phone && (!isPossiblePhoneNumber(phone) || !isValidPhoneNumber(phone))) {
      setErrorMsg({type: "phone", msg: "Invalid phone number"})
      return
    }

    if (creatorCode && !isValidCreatorCode(creatorCode)) {
      setErrorMsg({type: "creatorCode", msg: "Invalid creator code"})
      return
    }

    if (referralCode && !isValidReferralCode(referralCode)) {
      setErrorMsg({type: "referralCode", msg: "Invalid referral code"})
      return
    }

    const data = await saveSettings(userData)

    if (data.status === 401) {
      props.setSession(null)
      const { error } = await supabase.auth.signOut()
      navigate('/login')
      return
    } else if (data.status !== 200) {
      if (data.errorObj) {
        setErrorMsg(data.errorObj)
      } 
      return
    }

    setSaved(1)
    setUpdated(!updated)


    return
  }

  useEffect(() => {
    if (props.session) {
      fetchSettings()
    }
  }, [props.session, updated])

  useEffect(() => {
    if (saved) {
      const timer = setTimeout(() => {
        setSaved(0)
      }, 5000)

      return () => clearTimeout(timer);
    }
  }, [saved])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: '36px', width: '100%', alignItems: 'center', position: 'relative', boxSizing: 'border-box', pb: '130px' }}>

      {errorMsg && errorMsg?.type == "other" && errorMsg?.msg ? 
        <Alert sx={{ 
          position: 'fixed', top: '72px', width: {xs:'80%', md:'auto'}, 
          zIndex: 99, bgcolor: '#191919', 
          border: '2px solid #d32f2f', color: 'white', 
          fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
          alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
        }} severity="error" >
          {errorMsg?.msg}
        </Alert> 
      : null }

      {saved === 1 ? 
        <Alert sx={{ 
          position: 'fixed', top: '100px', width: {xs:'80%', md:'auto'}, 
          zIndex: 99, bgcolor: '#191919', 
          border: '2px solid #2e7d32', color: 'white', 
          fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
          alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
        }} severity="success" >
          Settings Saved
        </Alert> 
      : null }

      {from == '/login' && saved === null && !isVerified ? 
        <Alert sx={{ 
          position: 'fixed', top: '82px', width: {xs:'80%', md:'auto'}, 
          zIndex: 99, bgcolor: '#191919', 
          border: '2px solid #0288d1', color: 'white', 
          fontFamily: 'Poppins', fontSize: {xs:'14px', sm:'16px'}, fontWeight: 500,
          alignSelf: 'center', lineHeight: 1.2, alignItems: 'center'
        }} severity="info" >
          Welcome! Please complete the ID Verification below to start placing entries.
        </Alert> 
      : null }

      <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 600, fontSize: '32px', color: 'white', visibility: from == '/login' && saved === null && !isVerified ? 'hidden' : 'visible' }} >Settings</Typography>

      {!isLoading ?
        <Box sx={{ width: {xs: '90%', sm:'70%', md: '50%'}, maxWidth: '600px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '14px', mt: '24px' }}>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px', boxSizing: 'border-box' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
              Email
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              border: 2,
              borderColor: 'rgba(255, 255, 255, 0.2)',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              textAlign: 'left',
              height: '48px',
              padding: '0 10px', 
              width: '100%',
              boxSizing: 'border-box',
              mb: '4px'
            }}>
              <Input
                disableUnderline 
                fullWidth 
                disabled
                value={email}
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: '13px',
                  color: 'white',
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: 'gray',
                  },
                }} 
              />
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px', boxSizing: 'border-box' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
              Address
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              border: 2,
              borderColor: 'rgba(255, 255, 255, 0.2)',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              textAlign: 'left',
              height: '48px',
              padding: '0 10px', 
              width: '100%',
              boxSizing: 'border-box',
              mb: '4px'
            }}>
              <Input
                disableUnderline 
                fullWidth 
                disabled
                value={address}
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: '13px',
                  color: 'white',
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: 'gray',
                  },
                }} 
              />
            </Box>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px', boxSizing: 'border-box' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
              Birthday
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              border: 2,
              borderColor: 'rgba(255, 255, 255, 0.2)',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              textAlign: 'left',
              height: '48px',
              padding: '0 10px', 
              width: '100%',
              boxSizing: 'border-box',
              mb: '4px'
            }}>
              <Input
                disableUnderline 
                fullWidth 
                disabled
                value={birthday}
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: '13px',
                  color: 'white',
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: 'gray',
                  },
                }} 
              />
            </Box>
          </Box>

          
        
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px', boxSizing: 'border-box' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
              Phone Number
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              border: 2,
              borderColor: errorMsg && errorMsg?.type === "phone" ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              textAlign: 'left',
              height: '48px',
              padding: '0 10px', 
              width: '100%',
              boxSizing: 'border-box',
            }}>
              <PhoneInput
                international
                defaultCountry="US"
                className="phone-input"
                value={phone}
                onChange={setPhone}
              />
            </Box>
            <Typography sx={{ color: '#d32f2f', fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: '15px', mb: '-15px' }}>
              {errorMsg && errorMsg?.type === "phone" ? errorMsg.msg : null }
            </Typography>
          </Box>

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px', boxSizing: 'border-box' }}>
            <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
              Creator Code
            </Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              border: 2,
              borderColor: errorMsg && errorMsg?.type === "creatorCode" ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
              bgcolor: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              textAlign: 'left',
              height: '48px',
              padding: '0 10px', 
              width: '100%',
              boxSizing: 'border-box'
            }}>
              <Input
                disableUnderline 
                fullWidth 
                value={creatorCode}
                onChange={handleCreatorCodeChange}
                sx={{
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: '13px',
                  color: 'white',
                  "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: 'gray',
                  },
                }} 
              />
            </Box>
            <Typography sx={{ color: '#d32f2f', fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: '15px', mb: '-15px' }}>
              {errorMsg && errorMsg?.type === "creatorCode" ? errorMsg.msg : null }
            </Typography>
          </Box>

          {!isVerified ?
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4px', boxSizing: 'border-box' }}>
              <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start' }}>
                Referral Code (MUST BE SAVED BEFORE VERIFICATION)
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                border: 2,
                borderColor: errorMsg && errorMsg?.type === "referralCode" ? '#d32f2f' : 'rgba(255, 255, 255, 0.2)',
                bgcolor: 'rgba(255, 255, 255, 0.05)',
                borderRadius: '8px',
                textAlign: 'left',
                height: '48px',
                padding: '0 10px', 
                width: '100%',
                boxSizing: 'border-box'
              }}>
                <Input
                  disableUnderline 
                  fullWidth 
                  disabled={wasReferred}
                  value={referralCode}
                  onChange={handleReferralCodeChange}
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                    fontSize: '13px',
                    color: 'white',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'gray',
                    },
                  }} 
                />
              </Box>
              <Typography sx={{ color: '#d32f2f', fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'13px', sm:'15px'}, m: 0, lineHeight: 1, height: '15px', mb: '-15px' }}>
                {errorMsg && errorMsg?.type === "referralCode" ? errorMsg.msg : null }
              </Typography>
            </Box>
          :
            null
          }

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '8px', boxSizing: 'border-box', mt: '4px' }}>
            <Box sx={{ width: '100%', height: '30px', display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row', gap: '6px', boxSizing: 'border-box', alignContent: 'center' }}>
              <Box sx={{ width: {xs: '120px', sm: 'auto'} }} >
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start', lineHeight: 1.1 }}>
                  ID Verification (REQUIRED):
                </Typography>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'start', 
                alignItems: 'center', 
                flexDirection: 'row', 
                gap: '6px', 
                boxSizing: 'border-box',
                mr: {xs:'8px', sm:'16px'}
              }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: isVerified ? 600: 500, fontSize: {xs:'14px', sm:'16px'}, color: isVerified ? '#267800' :'#A80000', alignSelf: 'start', lineHeight: 1, mt: '3px' }}>
                  {isVerified ? 
                    'Complete' 
                  : 
                    props.validLocation ?
                      'Incomplete'
                    :
                      'Invalid Location'
                  }
                </Typography>
                <Box sx={{ width: {xs:'20px', sm:'22px'}, height: {xs:'20px', sm:'22px'}, color: isVerified ? '#267800' :'#A80000' }}>
                  {isVerified ? <FaRegCheckCircle size={'100%'} /> : <FaRegTimesCircle size={'100%'} /> }
                </Box>
              </Box>

              {!isVerified && props.validLocation ? 
                <PersonaComponent setIsVerified={setIsVerified} setUpdated={setUpdated} updated={updated} setSession={props.setSession} session={props.session} setErrorMsg={setErrorMsg} /> 
              : 
                null
              }
            </Box>
            <Box sx={{ width: '100%', height: '30px', display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'row', gap: '6px', boxSizing: 'border-box', alignContent: 'center', mt: {xs:'8px', sm:'0px'}  }}>
              <Box sx={{ width: {xs: '120px', sm: 'auto'} }} >
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: 400, fontSize: {xs:'14px', sm:'16px'}, color: 'white', alignSelf: 'start', lineHeight: 1.1 }}>
                  Prestige Membership:
                </Typography>
              </Box>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'start', 
                alignItems: 'center', 
                flexDirection: 'row', 
                gap: '6px', 
                boxSizing: 'border-box',
                mr: {xs:'8px', sm:'16px'}
              }}>
                <Typography sx={{ fontFamily: 'Montserrat', fontWeight: member ? 600: 500, fontSize: {xs:'14px', sm:'16px'}, color: member ? '#267800' :'#A80000', alignSelf: 'start', lineHeight: 1, mt: '3px' }}>
                  {member ? 'Active' : 'Inactive'}
                </Typography>
                <Box sx={{ width: {xs:'20px', sm:'22px'}, height: {xs:'20px', sm:'22px'}, color: member ? '#267800' :'#A80000' }}>
                  {member ? <FaRegCheckCircle size={'100%'} /> : <FaRegTimesCircle size={'100%'} /> }
                </Box>
              </Box>
              
              {!member ? 
                <Button variant="contained" onClick={() => window.open("https://esportsagent.gg/store", "_blank")} sx={{ 
                  bgcolor: 'rgba(168, 0, 0, 0.8)', 
                  color: 'white',
                  width: '70px',
                  height: '30px',
                  '&:hover': {
                    backgroundColor: '#A80000',
                  },
                  fontFamily: 'Montserrat',
                  fontWeight: 500,
                  fontSize: '12px',
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  lineHeight: 1,
                  px: '4px'
                }}>
                  Join
                </Button>
              :
                null
              }

            </Box>
          </Box>

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '6px', boxSizing: 'border-box', mt: '20px' }}>
            <Button variant="contained" onClick={() => handleSubmit()} sx={{ 
              bgcolor: 'rgba(168, 0, 0, 0.8)', 
              color: 'white',
              width: '100%',
              height: '48px',
              '&:hover': {
                  backgroundColor: '#A80000',
              },
              fontFamily: 'Montserrat',
              fontWeight: 600,
              fontSize: '16px',
              borderRadius: '8px',
              boxSizing: 'border-box'
            }}>
              Save Settings
            </Button>
          </Box>
        </Box>
      :
        <SettingsSkeleton />
      }
    </Box>
  )
}

export default SettingsPage